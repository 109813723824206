<template>

	<!-- Layout Footer -->
	<a-layout-footer>

		<!-- Footer Navigation Menu -->
		<a-menu mode="horizontal">
			<a-menu-item >Muffix Credit Union </a-menu-item>
			<a-menu-item>About</a-menu-item>
			<a-menu-item>Home</a-menu-item>
		</a-menu>
		<!-- / Footer Navigation Menu -->

		<!-- Footer Social Menu -->

		<!-- / Footer Social Menu -->
		
		<!-- Copyright Notice -->
		<p class="copyright">
      ©{{ new Date().getFullYear() }}
      <a href="https://muffixcreditunion.com">Muffix Credit Union</a>
		</p>
		<!-- / Copyright Notice -->

	</a-layout-footer>
	<!-- / Layout Footer -->

</template>

<script>

	export default ({
		data() {
			return {
			}
		},
	})

</script>

<style lang="scss" scoped>

	.nav-link svg {
		margin-right: 5px;
		vertical-align: middle;
	}
	.nav-link span {
		vertical-align: middle;
	}
	.ant-menu-submenu-popup {
		width: 100%;
	}

</style>